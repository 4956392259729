import React, { FC } from "react";
import { navigate } from "gatsby";
import { useEffect } from "react";
import { Loading } from "@components/Loading/Loading.component";

const Home: FC = () => {
  useEffect(() => {
    navigate("/app/login");
  }, []);
  return <Loading />;
};

export default Home;
